@keyframes spinner {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-reebelo {
  border: 0;
  animation: spinner 0.75s linear infinite;
  animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
}

button .spinner-reebelo {
  width: 1.5rem;
  height: 1.5rem;
}
