.curve-img {
  span {
    height: 100% !important;
  }
}

// prevent zendesk widget overlap with mobile menu
iframe#launcher {
  z-index: 40 !important;
}

.smartform-warning {
  color: #f25153;
  background-color: #fcf2f2;
}

// Text Outline
.custom-title-text-outline {
  text-shadow:
    -1px 1px 0 #1f2323,
    1px 1px 0 #1f2323,
    1px -1px 0 #1f2323,
    -1px -1px 0 #1f2323;
}

/* start - custom radio */
.custom-radio {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.custom-radio.small-version {
  font-size: 14px;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #1f2323;
}

.custom-radio.small-version .checkmark {
  top: 2px;
}

.custom-radio input:checked ~ .checkmark {
  background-color: #fff;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked ~ .checkmark::after {
  display: block;
}

/* stylelint-disable-next-line no-descending-specificity */
.custom-radio .checkmark::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #1f2323;
}

@media screen and (max-width: 1024px) {
  .custom-radio.small-version {
    font-size: 12px;
  }

  .custom-radio.small-version .checkmark {
    top: 0;
  }
}

@media screen and (max-width: 480px) {
  .custom-radio {
    padding-left: 20px;
    font-size: 14px;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 14px;
    width: 14px;
  }

  .custom-radio .checkmark::after {
    width: 7px;
    height: 7px;
  }
}

/* end - custom radio */

/* start - policies content */
.policies-content {
  --tw-text-opacity: 1;

  color: rgb(31 35 35 / var(--tw-text-opacity)); // text-gray-700
}

.policies-content .cta-button {
  font-weight: 700;
  text-align: center;
  border-radius: 0.375rem;
  display: inline-block;
  padding: 1rem;
  color: white;
  background-color: rgb(31 35 35);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
}

.policies-content .cta-button:hover {
  opacity: 0.9;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%);
}

.policies-content h1 {
  margin: 1.25rem 0 0.5rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  width: 100%;
  font-weight: 700;
  text-align: center;
}

.policies-content h2 {
  margin: 2.5rem 0 0.5rem;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.policies-content h3 {
  margin-top: 1rem;
  text-align: left;
  font-style: italic;
  font-weight: 600;
}

.policies-content p {
  margin-bottom: 0.75rem;
}

.policies-content a {
  --tw-text-opacity: 1;

  color: rgb(43 65 227 / var(--tw-text-opacity)); // text-blue-500
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.policies-content table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border-width: 1px;
  border-color: rgb(55 65 81);
}

.policies-content thead {
  width: 100%;
}

.policies-content th {
  border-width: 1px;
  border-color: rgb(55 65 81);
  padding: 0.5rem;
}

.policies-content td {
  border-width: 1px;
  border-color: rgb(55 65 81);
  padding: 0.5rem;
}

.policies-content ul {
  margin-left: 1rem;
  list-style-type: disc;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

@media not all and (min-width: 768px) {
  .policies-content h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

/* end - policies content */

@media (max-width: 1023px) {
  #new-pdp-klarna-placement::part(osm-container) {
    justify-content: center !important;
    margin: 12px 0 0 !important;
  }
}

#new-pdp-klarna-placement::part(osm-container) {
  margin: 5px 0 0;
  margin-left: -10px;
  border-radius: 0.375rem;
  border: none;
  padding: 0;
  line-height: 13px;
  flex-direction: row-reverse;
  justify-content: end;
}

#new-pdp-klarna-placement::part(osm-badge) {
  height: 20px;
  width: auto;
}

#new-pdp-klarna-placement::part(osm-message),
#new-pdp-klarna-placement::part(osm-cta) {
  color: #1f2323;
  font-size: 11px;
  outline: none;
  line-height: 13px;
  margin-right: 5px;
  display: inline;
}

@media (max-width: 640px) {
  #old-pdp-klarna-placement::part(osm-container) {
    justify-content: center !important;
  }
}

#old-pdp-klarna-placement::part(osm-container) {
  margin: 15px 0;
  margin-left: -10px;
  border: none;
  flex-direction: row-reverse;
  justify-content: start;
  padding: 0;
  line-height: 13px;
}

#old-pdp-klarna-placement::part(osm-badge) {
  height: 20px;
  width: auto;
}

#old-pdp-klarna-placement::part(osm-message),
#old-pdp-klarna-placement::part(osm-cta) {
  color: #1f2323;
  font-size: 11px;
  outline: none;
  line-height: 13px;
  margin-right: 5px;
  display: inline;
}

// Swiper
.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: auto !important;
  position: relative;
  transition-property: transform;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  animation: none !important;
}

.swiper-pointer-events {
  touch-action: pan-y;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  width: auto !important;
  left: auto !important;
  margin: 0;
  display: flex;
  justify-content: start;
  align-items: center;
}

.swiper-pagination-bullet {
  display: inline-block;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  text-align: center;
  color: #000;
  opacity: 1;
  background: #a5a5a5;
  margin-right: 8px;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #1f2323;
  width: 12px;
  height: 12px;
}

/* stylelint-disable-next-line selector-class-pattern */
.isPremium .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  box-sizing: border-box;
  border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
  border-radius: 50%;
  border-top-color: transparent;
}

.swiper:not(.swiper-watch-progress) .swiper-lazy-preloader,
.swiper-watch-progress .swiper-slide-visible .swiper-lazy-preloader {
  animation: swiper-preloader-spin 1s infinite linear;
}

.swiper-lazy-preloader-white {
  --swiper-preloader-color: #fff;
}

.swiper-lazy-preloader-black {
  --swiper-preloader-color: #000;
}

@media screen and (max-width: 480px) {
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin-right: 6px;
  }

  .swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
  }
}

.two-line-ellipsis {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Limit to two lines */
}

.three-line-ellipsis {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; /* Limit to two lines */
}

// Hero Swiper
.hero-swiper .swiper-pagination {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .hero-swiper .swiper-pagination {
    z-index: 50;
    position: absolute;
    bottom: 15px;
    display: flex;
    width: 100% !important;
    justify-content: center;
    align-items: center;
  }

  .hero-swiper.light .swiper-pagination-bullet {
    background: rgb(31 35 35 / 50%) !important;
  }

  .hero-swiper.dark .swiper-pagination-bullet {
    background: rgb(255 255 255 / 50%) !important;
  }

  .hero-swiper.light .swiper-pagination-bullet-active {
    background: #1f2323 !important;
  }

  .hero-swiper.dark .swiper-pagination-bullet-active {
    background: white !important;
  }
}

// Hide Recaptcha Badge
.grecaptcha-badge {
  visibility: hidden !important;
}
